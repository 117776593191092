
@import 'react-notifications/lib/notifications.css';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing:border-box;
}

.notification {
    box-sizing: content-box;
    position: fixed;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
    z-index: 200;
    transition: all 200ms ease-in;
}

.notification-success {
    border-radius: 5px;
    background-color: #3bbc60; /* Здесь вы можете установить желаемый цвет фона */
    color: white; /* Здесь вы можете установить желаемый цвет текста */
}

.notification-error {
    border-radius: 5px;
    background-color: #bc3b3b; /* Здесь вы можете установить желаемый цвет фона */
    color: white; /* Здесь вы можете установить желаемый цвет текста */
}

.notification-warning {
    border-radius: 5px;
    background-color: #c48819; /* Здесь вы можете установить желаемый цвет фона */
    color: white; /* Здесь вы можете установить желаемый цвет текста */
}