.canvasBlock {
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #f0f0f0;
}

.tableName {
    position: absolute;
    top: 20px;
    left: 400px;
    color: black;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing:border-box;
}

.nonSelectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.canvasBlock * {
    font-family: "Roboto Mono", sans-serif;
}

.arrow {
    cursor: pointer;
}

.arrowSelected, .arrow:hover {
    stroke: #69A2FF7F;
    stroke-width: 7px;
}

.arrowOption {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: black;
}

.relationPowerButton {
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.relationPowerButtonSelected {
    fill: #ccd4ff;
}

.relationPowerGroup:hover {
    cursor: pointer;

}

.relationPowerGroup:hover  .relationPowerButton {
    fill: #ccd4ff;
}

.relationPowerButton {
    pointer-events: auto;
}

.relationDeleteButton * {
    cursor: pointer;
    transition: all 300ms ease-in-out;
}

.relationDeleteButton:hover * {
    stroke: #ccd4ff;
}

.animatedArrow {
    stroke-dasharray: 100%;
    stroke-dashoffset: 100%;
}

.creatingArrow {
    stroke-dasharray: 20px 10px;
    animation: animateArrow 60s linear reverse;
    animation-iteration-count: infinite;
}

.table {
    opacity: 1;
    animation: draw 0.5s ease-in-out;

}

@keyframes draw {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes animateArrow {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 4000px;
    }
}