.versionMenuBlock {
    display: flex;
    gap: 20px;
    z-index: 10;
    position: absolute;
    height: auto;
    left: 430px;
    top: 10px;
    border-radius: 6px;
    transition: all ease-in-out 500ms;
    /*background-color: #ffffff;*/
}

.versionMenuBlockSwitched {
    left: 40px;
}

.versionButtonsBlock {
    display: flex;
    -webkit-box-shadow: 0px 0px 32px -2px rgba(34, 60, 80, 0.28);
    -moz-box-shadow: 0px 0px 32px -2px rgba(34, 60, 80, 0.28);
    box-shadow: 0px 0px 32px -2px rgba(34, 60, 80, 0.28);
    border-radius: 8px;
}

.leftButtonHistory, .middleButtonHistory, .rightButtonHistory {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #f6f6f6;
    cursor: pointer;
    transition: all ease-in-out 500ms;
}
.leftButtonHistory {
    border-radius: 8px 0 0 8px;
}

.rightButtonHistory {
    border-radius: 0 8px 8px 0;
}

.leftButtonHistory:hover, .rightButtonHistory:hover, .middleButtonHistory:hover, .versionBarIsOpen {
    background-color: #cae5ff;
}
.versionBarBlock {
    display: flex;
    background-color: #eeeeee;
    align-items: center;
    border-radius: 0 8px 8px 0;
    overflow: hidden;
    max-width: 0;
    padding: 0;
    transition: all ease-in-out 500ms;
}

.versionBarBlockIsOpen {
    max-width: 500px;
    padding: 0 10px;
}

.versionBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.versionBarPosition {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 30px;
    background-color: white;
    cursor: pointer;
    transition: all ease-in-out 200ms;
    
}

.versionBarPositionCurrent {
    background-color: #81e18c;
}

.versionBarPosition:hover {
    background-color: #d9ebff;
}

.versionBarPositionLine {
    width: 15px;
    height: 3px;
    background-color: white;
}

.versionBar:last-child .versionBarPositionLine {
    display: none;
}

.versionBarPositionIndicator {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background-color: rgba(172, 238, 202, 0);
    transition: all ease-in-out 500ms;
}

.versionBarPositionIndicatorGreen {
    background-color: #aceeca !important;
}

.versionBarPositionIndicatorBlue {
    background-color: #acc6ee !important;
}