* {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
}

* span, * button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.consoleBlock {
    width: 600px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 10px;
    overflow: hidden;
    max-height: calc(100vh - 40px);
}

.consoleHeader {
    cursor: pointer;
    padding: 0 8px;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #ffffff;
    transition: all ease-in-out 300ms;
}

.consoleHeader:hover {
    background-color: #f7faff;
}

.consoleHeader span {
    color: #545454;
}

.editorBlockClosed {
    position: relative;
    transition: all ease-in-out 500ms;
    width: 100%;
    background-color: #f7faff;
    padding: 0 20px 0 20px;
    max-height: 0;
}

.editorBlockOpened {
    overflow-x: hidden;
    height: auto;
    max-height: calc(100vh - 20px - 60px);
    padding: 10px 20px 10px 20px;
}

.editorBlockOpened::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар для браузеров, использующих WebKit (например, Chrome, Safari) */
}

.tablesEditorBlock {
    position: relative;
    max-width: 100%;
    width: min-content;
    overflow: hidden;
    margin-bottom: 10px;
}

.indicatorBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.editor {
    font-family: "Roboto Mono", sans-serif;
    width: 560px;
    max-width: 100%;
    height: 40vh;
}

.editor:focus {
    outline: none; /* Убирает выделение вокруг элемента при фокусе */
    box-shadow: none; /* Убирает тень */
}

.buttonsBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    margin-top: 10px;
}

.closeButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 90px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #2d7db9;
    color: #2d7db9;
    transition: all ease-in-out 300ms;
    font-size: 15px;
}

.saveButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 90px;
    border-radius: 8px;
    background-color: #2d7db9;
    color: white;
    transition: all ease-in-out 300ms;
    font-size: 15px;
    border: none;
}

.saveButtonDisabled {
    cursor: default;
    background-color: #6d8da6;
}

.saveButtonDisabled:hover {
    background-color: #6d8da6 !important;
}

.importButtonLabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 90px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #2d7db9;
    color: #2d7db9;
    transition: all ease-in-out 300ms;
    font-size: 15px;
}

.importButton {
    position: absolute; 
    top: 0;
    opacity: 0;
}

.saveButton:hover {
    background-color: #1d5f91;
}

.closeButton:hover {
    background-color: #f4f4f4;
}

.importButtonLabel:hover {
    background-color: #f4f4f4;
}
