* {
    font-family: "Montserrat", sans-serif;
}

.workBenchBlock {
    z-index: 10;
    position: absolute;
    width: 400px;
    height: calc(100% - 20px);
    left: 10px;
    top: 10px;
    -webkit-box-shadow: 0px 0px 32px -2px rgba(34, 60, 80, 0.28);
    -moz-box-shadow: 0px 0px 32px -2px rgba(34, 60, 80, 0.28);
    box-shadow: 0px 0px 32px -2px rgba(34, 60, 80, 0.28);
    border-radius: 20px;
    transition: all ease-in-out 500ms;
    background-color: white;
}



.workBenchBlockSwitched {
    left: -380px;
}

.workBenchHeaderBlock {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    /*-webkit-box-shadow: 0px 8px 10px -5px rgba(34, 60, 80, 0.6);*/
    /*-moz-box-shadow: 0px 8px 10px -5px rgba(34, 60, 80, 0.6);*/
    /*box-shadow: 0px 8px 10px -5px rgba(34, 60, 80, 0.6);*/
}

.workBenchTitleBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 20px 20px 0 0;
}

.workBenchTitleBlock span {
    font-size: 20px;
}

.workBenchButtons {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
    border-bottom: solid 1px #ebebeb;
    border-top: solid 1px #f1f1f1;
    padding: 0 60px 0 10px;
    background-color: white;

}

.workBenchButtons span {
    font-size: 18px;
    color: #8e8e8e;
}

.workBenchCreateButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    background-color: #3A5594;
    border-radius: 6px;
    height: 35px;
    transition: all ease-in-out 300ms;
}

.workBenchCreateButton:hover, .workBenchCloseButton:hover {
    background-color: #557bd0;
}

.workBenchCreateButton span {
    color: white;
    font-size: 16px;
}

.workBenchCloseButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 55px;
    width: 40px;
    height: 40px;
    z-index: 3;
    /*border-radius: 5px 10px 10px 5px;*/
    border-radius: 10px 0 0 10px;
    background-color: #3A5594;
    transition: all ease-in-out 300ms;
}

.workBenchCloseButtonSwitched {
    right: -30px;
    border-radius: 6px 10px 10px 6px;
}

.menuArrow {
    transform: rotate(0deg);
    transition: all ease-in-out 500ms;
}

.menuClosedArrow {
    transform: rotate(180deg);
}


.workBenchBodyBlock {
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
    border-radius: 0 0 35px 35px;
}

.workBenchBodyBlock::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар для браузеров, использующих WebKit (например, Chrome, Safari) */
}

.tableBlock {
    width: 100%;
}

.tableHeader {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background-color: #FAFAFA;
    border-top: solid 1px #eeeeee;
    transition: all ease-in-out 200ms;
    padding: 0 20px;
}

.tableHeaderSelected {
    background-color: #A5BBF0 !important;
}

.tableHeaderSelected .tableNameInput {
    color: #ffffff !important;
    background-color: #A5BBF0 !important;
}

.tableHeader:hover, .tableHeader:hover .tableNameInput {
    background-color: #F5F5F5;
}

.tableColorMark {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    cursor: grab;
}

.tableFieldColorMark {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    cursor: grab;
}

.openButtonNNameBlock {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.arrowReverse {
    transform: rotate(180deg);
}

.tableNameInput {
    cursor: pointer;
    border: rgba(90, 170, 230, 0) 1px solid;
    background-color: #FAFAFA;
    height: 26px;
    border-radius: 8px;
    padding: 5px;
    width: 200px;
    transition: all 200ms ease-in-out;
    font-weight: 500;
    font-size: 16px;
    color: #408fc3;
}
.tableNameInput:focus {
    outline: none; /* Убирает выделение вокруг элемента при фокусе */
    border: #e7e7e7 1px solid;
    box-shadow: none; /* Убирает тень */
}

.tableOptions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    height: 100%;
}

.tableOptionButton {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    padding: 2px;
    border-radius: 5px;
    transition: all ease-in-out 200ms;
}

.primaryKey svg {
    transition: all 200ms ease-in-out;
    opacity: 0;
}

.primaryKey:hover svg {
    opacity: 1;
}

.tableOptionButton:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.tableHeaderSelected .tableOptionButton:hover {
    background-color: #8dadfa !important;
}

.menuBlock, .favColorsBlock {
    cursor: default;
    position: fixed;
    width: 150px;
    overflow: hidden;
    z-index: 100000;
    border-radius: 6px;
}

.favColorsBlock {
    height: 95px;
    backdrop-filter: blur(6px);
}

.paletteBlock {
    position: fixed !important;
}

.react-colorful {
    height: 240px;
    width: 240px;
}

.menuItem, .saveColorButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
    background-color: rgba(20, 53, 128, 0.65);
    border-bottom: solid 1px rgba(155, 155, 155, 0.8);
    transition: all 300ms ease-in-out;
    backdrop-filter: blur(6px);
}

.colorPalette {
    gap: 8px;
    padding: 10px;
    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 70px;
    background-color: rgba(20, 53, 128, 0.65);
    backdrop-filter: blur(6px);
}

.color {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 4px;
}

.menuItem:hover, .menuItemSelected, .saveColorButton:hover {
    background-color: rgba(44, 86, 182, 0.65);
}

.menuItem span, .saveColorButton span {
    font-size: 14px;
    color: rgba(249, 251, 255, 0.8);
}

.menuItem:last-child {
    border-bottom: none;
}

.tableFieldsBlock {
    width: 100%;
    max-height: 0;
    transition: all ease-in-out 300ms;
}

.tableFieldsBlockOpened {
    max-height: 500px !important;
}


.tableFieldsBlockOpened::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар для браузеров, использующих WebKit (например, Chrome, Safari) */
}

.tableFieldBlock {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 40px;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    cursor: default !important;
}

.editMenu {
    cursor: default;
    position: fixed;
    width: 200px;
    height: auto;
    z-index: 100000;
    border-radius: 6px;
    background-color: rgba(20, 53, 128, 0.65);
    backdrop-filter: blur(6px);
    padding: 15px;
}

.editMenuArrow {
    position: absolute;
    /*background-color: rgba(20, 53, 128, 0.65);*/
    border-top: 10px solid transparent; /* Верхняя граница прозрачная */
    border-bottom: 10px solid transparent; /* Нижняя граница прозрачная */
    border-right: 12px solid rgba(20, 53, 128, 0.65); /* Правая граница черная, ширина определяет высоту треугольника */
    /*transform: rotate(45deg);*/
    top: 5px;
    left: -12px;
}

.changeConstraintBlock {
    display: block;
    width: 100%;
}

.editMenu span {
    font-size: 14px;
    color: #e0e0e0;
}

.changeConstraintButton {
    cursor: pointer;
    display: block;
    white-space: nowrap;
    margin-top: 8px;
    border: 1px #f6f6f6 solid;
    background: none;
    color: #f6f6f6;
    padding: 5px;
    border-radius: 4px;
    transition: all ease-in-out 300ms;
}

.changeConstraintButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.line {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: rgba(219, 229, 255, 0.35);
}

.inputBlock {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px
}

.fieldNameInput, .fieldTypeInput {
    border: rgba(90, 170, 230, 0) 1px solid;
    height: 26px;
    border-radius: 8px;
    padding: 5px;
    width: 120px;
    transition: all 300ms ease-in-out;
}

.fieldTypeInput {
    width: 120px;
    padding: 0 5px;
    border-radius: 8px 0 0 8px;
}

.fieldNameInput:focus, .fieldTypeInput:focus {
    outline: none; /* Убирает выделение вокруг элемента при фокусе */
    border: #5aaae6 1px solid;
    box-shadow: none; /* Убирает тень */
}

.fieldNameInput:hover, .fieldTypeInput:hover {
    border: #5aaae6 1px solid;
    transition: all 300ms ease-in-out;
}

* span {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
