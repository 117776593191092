.fieldPoint {
    cursor: pointer;
}



.field {
    transition: all 300ms ease-in-out;
    fill: white;
}

.fieldGroup:hover .field{
    fill: #eeeeee;
}